import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  AppBar, Toolbar, Typography, Button, Card, CardContent, CardHeader, List, ListItem, ListItemText,
  Box, Fab, Drawer, TextField, useMediaQuery, CircularProgress
} from '@mui/material';
import { ArrowUpward, Info, Train, Map, Scale, Menu, LocalShipping, DirectionsBoat, Event, Anchor } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


function ClientPage() {
  const [clientData, setClientData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [expandedCards, setExpandedCards] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState({ from: '', to: '' });

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const containerRef = useRef(null);

  const apiUsername = process.env.REACT_APP_API_USERNAME;
  const apiPassword = process.env.REACT_APP_API_PASSWORD;
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const privilegedUserId = process.env.REACT_APP_PRIVILEGED_USER_ID;

  // используем свой прокси
  // const proxyUrl = process.env.REACT_APP_PROXY_URL;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get('user');
  const apiStatusClient = `${apiBaseUrl}/${encodeURIComponent(user)}`;
  const clientName = location.state?.edrpou || 'Сторінка Клиента';

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const [wagonError, setWagonError] = useState('');

  const [selectedVessel, setSelectedVessel] = useState('');
  const [vessels, setVessels] = useState([]);


  // кнопка авто-скрола
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // дата на сегодня для отображения кораблей по текущему дню
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setDateRange({ from: formattedDate, to: formattedDate });
  }, []);


  // загрузка данных о клиенте при наличии user
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        setIsLoading(true);
        setError('');
        const authHeader = 'Basic ' + btoa(`${apiUsername}:${apiPassword}`);
        try {
          const response = await axios.get(apiStatusClient, {
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
            },
            responseType: 'json',
          });

          if (response.status === 200 && Array.isArray(response.data)) {
            setClientData(response.data);
            // setFilteredData(response.data);
          } else {
            setError('Користувач з таким EDRPOU не знайдений.');
          }
        } catch (error) {
          setError('Ошибка при загрузке данных.');
          console.error('Ошибка при загрузке данных');
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [user, apiUsername, apiPassword, apiStatusClient]);


 // через прокси
  // useEffect(() => {
  //   if (user) {
  //     const fetchData = async () => {
  //       setIsLoading(true);
  //       setError('');
        
  //       try {
  //         const response = await axios.get(proxyUrl, {
  //           params: {
  //             target: `${apiBaseUrl}/${encodeURIComponent(user)}`, 
  //             username: apiUsername,
  //             password: apiPassword,
  //           },
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           responseType: 'json',
  //         });

  //         if (response.status === 200 && Array.isArray(response.data)) {
  //           setClientData(response.data);
  //           setFilteredData(response.data);
  //         } else {
  //           setError('Користувач з таким EDRPOU не знайдений.');
  //         }
  //       } catch (error) {
  //         setError('Ошибка при загрузке данных.');
  //         console.error('Ошибка при загрузке данных:', error);
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [user]);


  // фильтрация и группировка данных по диапазону дат и номеру вагона
  // useEffect(() => {
  //   const filtered = clientData.filter((item) => {
  //     if (!item.ДатаДок) return false;

  //     const [datePart] = item.ДатаДок.split(' ');
  //     const itemDate = new Date(datePart.split('.').reverse().join('-'));
  //     const fromDate = dateRange.from ? new Date(dateRange.from) : null;
  //     const toDate = dateRange.to ? new Date(dateRange.to) : null;
  //     const isDateInRange =
  //       (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate);

  //     const isWagonMatch = searchValue
  //       ? item.НомерВагона && item.НомерВагона.toString().includes(searchValue)
  //       : true;

  //     return isDateInRange && isWagonMatch;
  //   });

  //   // Проверка наличия данных за текущий день
  //   if (filtered.length === 0) {
  //     setError('Завантаженя на сьогодні відсутні');
  //   } else {
  //     setError(''); // Сбрасываем сообщение об ошибке, если данные найдены
  //   }

  //   // Сортировка по дате в порядке возрастания
  //   filtered.sort((a, b) => {
  //     const dateA = new Date(a.ДатаДок.split(' ')[0].split('.').reverse().join('-'));
  //     const dateB = new Date(b.ДатаДок.split(' ')[0].split('.').reverse().join('-'));
  //     return dateA - dateB;
  //   });

  //   const grouped = filtered.reduce((acc, item) => {
  //     const vessel = item.Судно;
  //     if (!acc[vessel]) {
  //       acc[vessel] = [];
  //     }
  //     acc[vessel].push(item);
  //     return acc;
  //   }, {});
  //   setGroupedData(grouped);
  //   setFilteredData(filtered);
  // }, [clientData, dateRange, searchValue]);

  // фильтрация и группировка данных по диапазону дат, номеру вагона и судну
  useEffect(() => {
    const filtered = clientData.filter((item) => {
      if (!item.ДатаДок) return false;

      const [datePart] = item.ДатаДок.split(' ');
      const itemDate = new Date(datePart.split('.').reverse().join('-'));
      const fromDate = dateRange.from ? new Date(dateRange.from) : null;
      const toDate = dateRange.to ? new Date(dateRange.to) : null;

      // Проверяем, попадает ли дата в диапазон
      const isDateInRange =
        (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate);

      // Проверяем, совпадает ли выбранное судно
      const isVesselMatch = selectedVessel ? item.Судно === selectedVessel : true;

      // Проверяем, совпадает ли номер вагона
      const isWagonMatch = searchValue
        ? item.НомерВагона && item.НомерВагона.toString().includes(searchValue)
        : true;

      return isDateInRange && isVesselMatch && isWagonMatch;
    });

    // Проверка наличия данных для выбранных параметров
    if (filtered.length === 0) {
      setError('Дані по заданим параметрам відсутні');
    } else {
      setError('');
    }

    // Группировка отфильтрованных данных по судну
    const grouped = filtered.reduce((acc, item) => {
      const vessel = item.Судно;
      if (!acc[vessel]) {
        acc[vessel] = [];
      }
      acc[vessel].push(item);
      return acc;
    }, {});

    setGroupedData(grouped);
    // setFilteredData(filtered);
  }, [clientData, dateRange, searchValue, selectedVessel]);


  // проверка существование номера вагона
  useEffect(() => {
    if (searchValue && searchValue.length === 8) {
      // Проверяем, есть ли номер вагона в данных
      const wagonExists = clientData.some((item) => item.НомерВагона === searchValue);

      if (!wagonExists) {
        setWagonError('Вагон не знайдений. Перевірте коректність номеру');
      } else {
        setWagonError(''); // Сбрасываем сообщение, если номер найден
      }
    }
  }, [searchValue, clientData]);

  // Получаем все судна
  useEffect(() => {
    const uniqueVessels = [...new Set(clientData.map(item => item.Судно))].filter(Boolean);
    setVessels(uniqueVessels);
  }, [clientData]);

  // поиск
  const handleSearch = () => {
    setDrawerOpen(false);
  };

  // сброс всех фильтров и значений полей
  const resetFilters = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    setSearchValue('');
    setWagonError('');
    setDateRange({ from: formattedDate, to: formattedDate });
    setSelectedVessel('');
    // setFilteredData(clientData);
    setExpandedCards([]);
  };

  // выход 
  const handleLogout = () => {
    navigate('/');
  };

  // кнопка открытия панели
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // автоскрол вверх + закрытие всех карточек
  const scrollToTop = (e) => {
    e.stopPropagation();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setExpandedCards([]); // Закрываем все карточки
  };

  // закрыть-открыть карточки
  const toggleCard = (index) => {
    if (user === privilegedUserId) {
      setExpandedCards((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
    }
  };

  // поиск по судну
  const handleVesselChange = (event) => {
    setSelectedVessel(event.target.value);
  };


  return (
    <Box sx={{ backgroundColor: '#f4f6f8', minHeight: '100vh', paddingBottom: 4, position: 'relative', overflow: 'hidden', }} >
      <Anchor sx={{
        position: 'absolute',
        top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '250px',
        color: '#1976d2',
        opacity: 0.05,
        pointerEvents: 'none',
      }}
      />

      {/* Хедер*/}
      <AppBar position="static" sx={{ backgroundColor: '#1976d2' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {clientName}
          </Typography>
          <Button
            color="inherit"
            onClick={toggleDrawer(true)}
            sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
          >
            <Menu />
          </Button>
          <Button color="inherit" onClick={handleLogout}>Вийти</Button>
        </Toolbar>
      </AppBar>

      {/* Блок с итогами суточный под хедером*/}
      <Box sx={{ padding: 2, backgroundColor: '#e3f2fd', textAlign: 'start' }}>
        <Typography variant="h6" sx={{ color: '#1976D2', display: 'flex', alignItems: 'center' }}>
          <Event sx={{ marginRight: 1 }} />
          {new Date(dateRange.from).toLocaleDateString('uk-UA')} - {new Date(dateRange.to).toLocaleDateString('uk-UA')}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#0288d1', display: 'flex', alignItems: 'center' }}>
          <DirectionsBoat sx={{ marginRight: 1 }} />
          {Object.keys(groupedData).length}
        </Typography>
      </Box>

      {/* Боковая панель фильтров и поиска */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 300, padding: 3 }}>
          <img
            src="https://cdn1.komiz.io/16089838/share/front/logo.png"
            alt="Logo"
            style={{ width: '60px', marginBottom: '16px' }}
          />

          <DirectionsBoat
            sx={{
              position: 'absolute',
              top: '75%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '150px',
              // color: '#ffffff',
              opacity: 0.1,
              pointerEvents: 'none',
              color: '#1976d2',
            }}
          />

          <Typography variant="h6" gutterBottom>
            Пошук та фільтри
          </Typography>

          <TextField
            label="Номер вагону"
            variant="outlined"
            fullWidth
            value={searchValue}
            onChange={(e) => {
              const value = e.target.value;
              // ввод только цифр
              if (/^\d*$/.test(value)) {
                setSearchValue(value);
                // Проверяем количество символов
                if (value.length !== 8 && value.length > 0) {
                  setWagonError('Перевірте коректність номера');
                } else {
                  setWagonError(''); // Сброс сообщения для дальнейшей проверки
                }
              } else {
                setWagonError('Номер вагона може містити лише цифри');
              }
            }}
            error={!!wagonError}
            helperText={wagonError}
            inputProps={{
              maxLength: 8,
              inputMode: 'numeric', // Мобильные устройства покажут цифровую клавиатуру
            }}
            sx={{ marginBottom: 2 }}
          />

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Судно</InputLabel>
            <Select
              value={selectedVessel}
              onChange={handleVesselChange}
              label="Судно"
            >
              <MenuItem value="">
                <em>Вcі судна</em>
              </MenuItem>
              {vessels.map((vessel, index) => (
                <MenuItem key={index} value={vessel}>
                  {vessel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Дата з"
            type="date"
            variant="outlined"
            fullWidth
            value={dateRange.from}
            onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="Дата по"
            type="date"
            variant="outlined"
            fullWidth
            value={dateRange.to}
            onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSearch}
            sx={{
              backgroundColor: '#1976d2',
              '&:hover': { backgroundColor: '#115293' },
            }}
          >
            Пошук
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={resetFilters}
            sx={{ marginTop: 2 }}
          >
            Скинути
          </Button>

        </Box>
      </Drawer>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      ) : (

        <Box sx={{ marginTop: 4, padding: isMobile ? 1 : 3 }} ref={containerRef}>
          {Object.entries(groupedData).map(([vessel, items], index) => {
            const totalWeight = items.reduce((sum, item) => sum + (parseFloat(item.Вес) || 0), 0);
            const totalWagonNumber = items.length;
            const dateDoc = items[0]?.ДатаДок || 'Неизвестно'; // Получаем дату из первого элемента
            const isExpanded = expandedCards.includes(index);

            // Подсчет количества каждого статуса
            const statusCounts = items.reduce((acc, item) => {
              const status = item.СтатусУЗНакладнойТТН || 'Неизвестно';
              acc[status] = (acc[status] || 0) + 1;
              return acc;
            }, {});

            // Форматируем строку со статусами и количеством
            const statusSummary = Object.entries(statusCounts)
              .map(([status, count]) => `${status}: ${count}`)
              .join(', ');

            return (
              <Card
                key={index}
                sx={{
                  marginBottom: 3,
                  boxShadow: 3,
                  overflow: 'hidden',
                  borderRadius: 2,
                  transition: 'all 0.3s',
                  padding: isMobile ? 1 : 2,
                }}
              >
                <CardHeader
                  onClick={() => toggleCard(index)}
                  title={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <DirectionsBoat sx={{ color: '#1976d2', marginRight: 1 }} />
                      <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ color: '#1976D2' }}>
                        {vessel}
                      </Typography>
                    </Box>
                  }
                  titleTypographyProps={{ variant: isMobile ? 'h6' : 'h5', color: '#1976D2' }}
                  subheader={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Event sx={{ color: '#0288d1', marginRight: 0.5, fontSize: '1rem' }} /> {dateDoc} |
                        <Scale sx={{ color: '#0288d1', marginLeft: 1, marginRight: 0.5, fontSize: '1rem' }} /> {(totalWeight / 1000).toFixed(2)} т |
                        <Train sx={{ color: '#0288d1', marginLeft: 1, marginRight: 0.5, fontSize: '1rem' }} /> {totalWagonNumber}
                      </Typography>
                      <Box sx={{ marginTop: 1 }}>
                        <Info sx={{ color: '#0288d1', verticalAlign: 'middle', fontSize: '1rem' }} />
                        <span style={{ color: '#0288d1' }}>{statusSummary}</span>
                      </Box>
                    </Box>
                  }
                  sx={{
                    backgroundColor: '#90caf9',
                    padding: isMobile ? 1 : 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                />

                {isExpanded && (
                  <CardContent sx={{ padding: isMobile ? 1 : 2 }}>
                    <List dense={isMobile}>
                      {items.map((item, idx) => (
                        <ListItem
                          key={idx}
                          sx={{
                            display: 'flex',
                            flexDirection: isLargeScreen ? 'row' : 'column',
                            borderBottom: '1px solid #ddd',
                            alignItems: isLargeScreen ? 'center' : 'flex-start',
                            padding: isMobile ? 0.5 : 1,
                            justifyContent: 'space-between',
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: '0.80rem' }}>
                                <Info sx={{ color: '#90caf9', fontSize: 'large', }} /> {item.СтатусУЗНакладнойТТН}
                              </Typography>
                            }
                            sx={{ flex: 1.5 }}
                          />
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: '0.80rem' }}>
                                <Train sx={{ color: '#90caf9', fontSize: 'large' }} /> {item.СтанцияОтправления}
                              </Typography>
                            }
                            sx={{ flex: 2 }}
                          />
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: '0.80rem' }}>
                                <Map sx={{ color: '#90caf9', fontSize: 'large' }} /> {item.Станция}
                              </Typography>
                            }
                            sx={{ flex: 2 }}
                          />
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: '0.80rem' }}>
                                <LocalShipping sx={{ color: '#90caf9', fontSize: 'large' }} /> {item.Груз}
                              </Typography>
                            }
                            sx={{ flex: 2 }}
                          />
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: '0.80rem' }}>
                                <Scale sx={{ color: '#90caf9', fontSize: 'large' }} /> {item.Вес}
                              </Typography>
                            }
                            sx={{ flex: 1 }}
                          />
                          <ListItemText
                            primary={
                              <Typography variant="body2" sx={{ fontSize: '0.80rem' }}>
                                <Train sx={{ color: '#90caf9', fontSize: 'large' }} /> {item.НомерВагона}
                              </Typography>
                            }
                            sx={{ flex: 1 }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                )}
              </Card>
            );
          })}
        </Box>
      )}
      {showScrollButton && (
        <Fab
          color="primary"
          aria-label="scroll-to-top"
          sx={{ position: 'fixed', bottom: 16, right: 16, borderRadius: 1, width: 40, height: 40, }}
          onClick={scrollToTop}
        >
          <ArrowUpward />
        </Fab>
      )}

    </Box>
  );
}

export default ClientPage;

