import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLock } from 'react-icons/fa';
import {
  Container,
  TextField,
  Typography,
  Button,
  Paper,
  Box,
  InputAdornment,
  Stack,
} from '@mui/material';

function Login() {
  const navigate = useNavigate();
  const [edrpou, setEdrpou] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();

    // Проверка пароля
    if (password === '1234') {
      // Переход на страницу клиента с EDRPOU в качестве параметра
      navigate(`/client?user=${encodeURIComponent(edrpou)}`, {
        state: { edrpou },
      });
    } else {
      alert('Неверный пароль!');
    }
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#f0f2f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={4}
          sx={{
            padding: 4,
            borderRadius: 3,
            width: '100%',
            textAlign: 'center',
            backgroundColor: '#ffffff',
          }}
        >
          <img
            src="https://cdn1.komiz.io/16089838/share/front/logo.png"
            alt="Logo"
            style={{ width: '80px', marginBottom: '16px' }}
          />
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontSize: '18px' }}>
            Статуси вантажів
          </Typography>
          <Box component="form" onSubmit={handleLogin}>
            <Stack spacing={3}>
              <TextField
                label="Код ЕДРПОУ"
                variant="outlined"
                fullWidth
                value={edrpou}
                onChange={(e) => setEdrpou(e.target.value)}
                placeholder="Введите код ЕДРПОУ"
                required
                InputLabelProps={{
                  style: { fontSize: '0.9rem' },
                }}
                InputProps={{
                  style: { fontSize: '0.9rem' },
                }}
              />
              <TextField
                label="Пароль"
                variant="outlined"
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Введите пароль"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaLock color="#1976d2" />
                    </InputAdornment>
                  ),
                  style: { fontSize: '0.9rem' },
                }}
                InputLabelProps={{
                  style: { fontSize: '0.9rem' },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  padding: '10px',
                  backgroundColor: '#1976d2',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
              >
                Увійти
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default Login;
